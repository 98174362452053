<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Sports'}">统计显示</router-link>
          <router-link :to="{name: 'SportsTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'SportsChart'}">图表显示</router-link>
        </div>
      </div>
    </div>
    <div class="add-box">
      <Row>
        <Col span="2" offset="22">
          <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
            <router-link class="new-doc fr" :to="{name: 'SportsAdd'}">添加信息</router-link>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="sportsList" ref="table" @on-selection-change="handleSelect"></Table>

      <Row>
        <Col span="12">
          <div class="table-oprate">
            <!-- <Checkbox>全选</Checkbox> -->
            <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
        </Col>
        <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="sportsData.page" :page-size="sportsData.size" @on-change="changePage"></Page>
      </div>
    </div>

  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '运动日期', key: 'date', align: 'center' },
				// {title: '运动时间', key: 'time', align: 'center'},
				{ title: '总消耗', key: 'total', align: 'center' },
				{ title: '总有效消耗量', key: 'total_effective', align: 'center' },
				{ title: '总运动步数', key: 'total_step', align: 'center' },
				{ title: '总运动时长', key: 'total_time', align: 'center' },
				{
					title: '星星',
					key: 'score',
					align: 'center',
					render: (h, params) => {
						if (params.row.score === 0.5) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star-half',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else if (params.row.score === 1) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else if (params.row.score === 1.5) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star-half',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else if (params.row.score === 2) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else if (params.row.score === 2.5) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star-half',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else if (params.row.score === 3) {
							return h('div', [
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
								h('Icon', {
									attrs: {
										type: 'ios-star',
										size: 22,
										color: '#fdad00',
									},
								}),
							]);
						} else {
							return h('i');
						}
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											let id = this.sportsList[params.index].id;
											MemberService.sportsDetail({ id: JSON.stringify(id) }).then(() => {
												this.$router.push({
													name: 'SportsEdit',
													params: { member_id: this.memberId, sport_id: id },
												});
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: '确定删除吗',
												onOk: () => {
													this.handleSingleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											let id = this.sportsList[params.index].id;
											MemberService.sportsDetail({ id: JSON.stringify(id) }).then(() => {
												this.$router.push({
													name: 'SportsEdit',
													params: { member_id: this.memberId, sport_id: id },
												});
											});
										},
									},
								}),
							]);
						}
					},
				},
			],
			sportsData: {
				index: 1,
				select_date: '',
				page: 1,
				size: 10,
				monitor_id: '',
			},
			sportsList: [],
			pageTotal: 0,
			deleteItem: {
				ids: [],
			},
			power: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},
	methods: {
		handleInit() {
			this.sportsData.member_id = this.memberId;
			MemberService.sportsQuery(this.sportsData).then((data) => {
				this.pageTotal = data.data_show.row_size;
				this.sportsList = data.data_show.list;
				this.deleteItem.ids = [];
				this.sportsList.forEach((item) => {
					item.total = `${item.total}kcal`;
					item.total_time = `${item.total_time}min`;
					item.total_effective = `${item.total_effective}kcal`;
					item.total_step = `${item.total_step}步`;
				});
			});
		},
		changePage(page) {
			this.sportsData.page = page;
			this.handleInit();
		},
		handleChangeDate(value) {
			this.sportsData.select_date = value.join(',');
			this.handleInit();
		},
		handleSelect(selection) {
			this.deleteItem.ids = [];
			selection.forEach((item) => {
				this.deleteItem.ids.push(item.id);
			});
		},
		// 删除一个会员
		handleSingleRemove(index) {
			let req = [this.sportsList[index].id];
			MemberService.sportsDel({ id_str: JSON.stringify(req) }).then(() => {
				this.handleInit();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个会员
		handleAllRemove() {
			if (this.deleteItem.ids.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			MemberService.sportsDel({ id_str: JSON.stringify(this.deleteItem.ids) }).then(() => {
				this.handleInit();
				this.deleteItem.ids = [];
			});
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.sportsData.monitor_id = this.monitorId;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}
</style>
